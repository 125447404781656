import React from "react";
import ContactUs from '../ContactUs/ContactUs';




function Contact(props) {

  return (
    <div className='contact main-content'>
      <div className="contact-page page">
        <ContactUs />
      </div>
    </div>
  )
}

export default Contact;